<template>
  <cui-a-c-l :roles="[1]" redirect>
    <div>
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
              <div class="cui__utils__heading mb-0">
                <strong>Team</strong>
              </div>
              <div class="text-muted">Edit your team information</div>
            </div>
            <div class="card-body">
              <a-form :form="form" @submit.prevent="update">
                <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Team Name">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>Team's name.</span>
                    </template>
                    <a-input placeholder="Team Name"
                             v-decorator="['name', { initialValue: team.name ,
                         rules: [
                           { required: true, message: 'Team Name is required.' },
                           { max: 64, message: 'Maximum 64 characters allowed.'}
                           ]}, ]"
                             :readOnly="!$nCan('team', 'update')"/>
                  </a-tooltip>
                </a-form-item>
                <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Website">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>Team's website address.</span>
                    </template>
                    <a-input placeholder="Website"
                             v-decorator="['website', { initialValue: team.website ,
                  rules: [
                             { max: 64, message: 'Maximum 64 characters allowed.'}
                         ]
                }]"/>
                  </a-tooltip>
                </a-form-item>
                <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Country">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>Team's country.</span>
                    </template>
                    <a-select
                      v-decorator="['country', { initialValue: (team.country === '' ? [] : team.country) }]"
                      placeholder="Please select a country"
                      :showSearch="true"
                      :filterOption="true"
                      optionFilterProp="children"
                      :disabled="!$nCan('team', 'update')"
                    >
                      <a-select-option v-for="obj in info.countries" :key="obj.CC">{{ obj.Country }}</a-select-option>
                    </a-select>
                  </a-tooltip>
                </a-form-item>
                <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="City">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>Team's city.</span>
                    </template>
                    <a-input placeholder="City" v-decorator="[ 'city', { initialValue: team.city,
                 rules: [
                           { max: 20, message: 'Maximum 20 characters allowed.'}
                           ]}]" :readOnly="!$nCan('team', 'update')"/>
                  </a-tooltip>
                </a-form-item>
                <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Zip Code">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>Team's zip code.</span>
                    </template>
                    <a-input type="number" placeholder="Zip Code" v-decorator="[ 'zipCode', { initialValue: team.zipCode,
                rules: [{transform: function(value) { return Number(value)}, type: 'integer'}] }]"/>
                  </a-tooltip>
                </a-form-item>
                <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Address">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>Team's address.</span>
                    </template>
                    <a-input placeholder="Address" v-decorator="[ 'address', { initialValue: team.address,
                 rules: [
                           { max: 64, message: 'Maximum 64 characters allowed.'}
                           ]}]" :readOnly="!$nCan('team', 'update')"/>
                  </a-tooltip>
                </a-form-item>
                <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Phone">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>Team's phone number.</span>
                    </template>
                    <a-input @keydown="phoneRegExp($event)" placeholder="Phone" title="+ not allowed." v-decorator="[ 'phone', { initialValue: team.phone ,
                rules: [
                  { message: 'Invalid phone number.' },
                  { max: 64, message: 'Maximum 20 characters allowed.'}
                  ]}]"/>
                  </a-tooltip>
                </a-form-item>
                <a-divider class="mt-3"></a-divider>
                <div class="ant-row ant-form-item">
                  <div class="ant-col ant-col-xs-24 ant-col-sm-4 ant-form-item-label"></div>
                  <div class="ant-col ant-col-xs-24 ant-col-sm-12 ant-form-item-control-wrapper">
                    <button type="submit" v-if="$nCan('team', 'update')" class="btn btn-success px-5">Save Changes
                    </button>
                  </div>
                </div>
              </a-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </cui-a-c-l>
</template>

<script>
import { mapState } from 'vuex'
// import phoneRegExp from '@/services/utils'
import CuiACL from '@/components/cleanui/system/ACL/index'

export default {
  components: {
    CuiACL,
  },
  computed: {
    ...mapState(['team', 'info']),
  },
  data() {
    return {
      form: this.$form.createForm(this),
      teamData: null,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
      },
    }
  },
  methods: {
    update() {
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(values)
          this.$store.dispatch('team/UPDATE_TEAM', { payload: values })
        }
      })
    },
    phoneRegExp(e) {
      if ((/^[0-9,+]$/.test(e.key) && /^\+?\d*$/.test(e.target.value + e.key)) || e.key === 'Backspace') {
        return true
      } else {
        e.preventDefault()
      }
    },
  },
  created() {
    this.$store.dispatch('team/LOAD_TEAM_DATA')
    this.$store.dispatch('info/LOAD_COUNTRIES')
  },
}
</script>
<style lang="css" scoped>
.ant-form-item {
  margin-bottom: 5px !important;
}
</style>
